@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "HyundaiSansHead-Regular",Arial,sans-serif;
$boldFontFamily: "HyundaiSansHead-Bold",Arial,sans-serif;
$boldFontWeight: 400;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #000000;
$linkColor: #007FA5;
$labelColor: #000000;
$formColor: #000000;
$utilColor: #007FA5;
$priceColor: #000000;
$lightColor: #000000;
$btnColorAlpha: #ffffff;
$btnColorBeta: #002C5F;
$btnColorStep: #ffffff;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #000000;
$subtitleSecondaryColor: #ffffff;
$successColor: #007FA5;
$warningColor: #007FA5;
$errorColor: #E10600;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #002C5F;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: transparent;
$colorBtnBeta: #002C5F;
$colorBtnOutlineAlpha: #002C5F;
$colorBtnOutlineBeta: #002C5F;
$paddingButtonPrimary: 16px 16px;
$paddingButtonSecondary: 16px 16px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000000;
$borderSizeForm: 1px solid;

$garageBackground: #ffffff;
$garageTextColor: #000000;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #F6F3F2;
$colorTopbarLogin: #000000;

$progressBarTitleBG: #002C5F;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #000000;
$progressBarCloseButtonBG: #002C5F ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #000000;
$paymentCalculatorClosedColor: #ffffff;
$paymentCalculatorPanelBG: #ffffff;
$paymentCalculatorTermWrapperBG: #ffffff;
$paymentCalculatorTermWrapperColor: $utilColor;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #F6F3F2;
$paymentCalculatorTermColor: #000000;

$calculatorTabsColor: #000000;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #000000;
$calculatorCloseTabsColor: #ffffff;
$calculatorMainColor: #ffffff;
$calculatorButton: $utilColor;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__hyundai{
            .LoginBanner .login-content,
            .TransactionSummary--wrapper--title{
                height: 37px !important;
            }
            .sr-text.is-bold.transaction-title{
                font-size: 12px;
            }
            .TransactionAction--single{
                button .label{
                    justify-content: center;
                }
            }
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__hyundai{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .sr-topbar-login{
            padding: 0;
        }
        .LoginBanner .login-content{
            display: flex;
            padding: 0;
        }
        .message-handler.warning{
            background-color: $utilColor;
            color: #FFFFFF;
        }
        /** Buttons **/
        .sr-button-1,
        .sr-button-2{
            box-shadow: none !important;
            transition: 0.2s ease-in-out !important;
            justify-content: center;
            align-items: center;
            text-transform: unset !important;
            font-family: $fontFamily !important;
            &:hover{
                opacity: 1 !important;
            }
            &.vehicle-cta-btn{
                height: 56px !important;
                font-size: 20px;
            }
        }
        .sr-button-1{
            border: 2px solid #002C5F !important;
            &:hover{
                border-color: #007FA5 !important;
                background: #007FA5  !important;
            }
        }
        .sr-button-2{
            background: transparent !important;
            color: #002C5F !important;
            border: 2px solid #002C5F !important;
            &:hover{
                border-color: #007FA5 !important;
                background: #007FA5 !important;
                color: #ffffff !important;
            }
        }
        .sr-button-outline-1,
        .sr-button-outline-2{
            border: 2px solid;
            &:hover:not(.is-disabled){
                opacity: 1 !important;
                color: #FFFFFF !important;
                border-color: #007FA5 !important;
                background: #007FA5 !important;
            }
        }
        .TransactionAction--single__amount.sr-button-1.is-small{
            background: $utilColor !important;
            border: none !important;
        }
        .widget-vehicle-quote-btn-secondary.sr-button-outline-2.is-alternate{
            background-color: transparent !important;
            border-color: #FFFFFF !important;
            color: #FFFFFF !important;
            &:hover{
                color: $utilColor !important;
                background-color: #FFFFFF !important;
            }
        }
        .confirm_tradein--icon{
            color: $utilColor;
            border-color: $utilColor;
            icon{
                color: $utilColor;
            }
        }
        /** Login and Progress Bar **/
        .sr-progressBar .TransactionSummaryUnit--single:nth-child(3){
            border-bottom-color: #007FA5 !important;
            background-color: #E6E6E6;
        }
        
        .sr-progressBar .TransactionSummaryUnit--single:nth-child(2){
            border-bottom-color: rgba(0, 127, 165, 0.4) !important;
            background-color: #F6F3F2;
            &:before{
                border-left-color: #F6F3F2;
            }
        }
        
        .sr-progressBar .TransactionSummaryUnit--single:first-child{
            border-bottom-color: rgba(0, 127, 165, 0.2) !important;
            background-color: #FFFFFF;
            &:before{
                border-left-color: #FFFFFF;
            }
        }
        .TransactionSummary--wrapper--title.is-open+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single{
            border-bottom-color: transparent !important;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            background-color: #000000 !important;
            border-bottom-color: $utilColor !important;
            &:before{
                border-left-color: #000000 !important;
            }
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate .wrapper-icon img{
            filter: invert(100%) grayscale(100%) brightness(2);
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,$utilColor 55%);
        }
        .TransactionProgressBar--progression{
            background-color: $utilColor;
        }
        
        .dropdown-content.sr-topbar-login{
            #profile-link.is-light,
            #logout-link.is-light{
                color: #000000;
            }
        }
        /** SignUp and Login Forms **/
        .sr-modal--white-close{
            color: #000000;
        }
        .widget-register-popupElement .SignUpForm .ModalHeader .sub-text,
        .widget-login-popupElement .SignInForm .ModalHeader .sub-text{
            color: $utilColor;
            font-family: $fontFamily;
        }
        /** VLP **/
        .listing-new-tile .listing-new-tile-wrapper{
            border-radius:0px;
        }
        .method-button-payment-bar button,
        .listing-used-filters-head .listing-used-filters-head-title,
        .listing-used-filters-head .listing-used-filters-head-button p,
        .listing-new-tile .listing-new-tile-drivePowerTrains .new-car-name,
        .section-summary__content .trim{
            text-transform: unset;
        }
        .model-list li .model-label{
            text-transform: capitalize;
        }
        .listing-tile,
        .listing-used-bodystyle,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button{
            border-radius: 0px;
        }
        .listing-tile-package-head.is-uppercase{
            text-transform: unset !important;
        }
        .listing-tile .listing-tile-image .car-tagline{
            color: #FFFFFF;
        }
        .listing-tile-note,
        .listing-tile-note .vehicle-note{
            background: #f2f2f2;
        }
        .listing-used-bodystyle.selected,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected,
        .model-button.selected{
            border-color: $utilColor;
            color: $utilColor;
        }
        .sr-slide .rc-slider-handle,  .sr-slide .rc-slider-track{
            background-color: $utilColor !important;
        }        
        .model-label.selected.is-price{
            font-weight: bold;
        }
        .listing-used-filters-head .listing-used-filters-head-button p.current-filter{
            a{
                color: $utilColor;
            }
            &:after{
                background-color: $utilColor;
            }
        }
        .method-button-payment-bar button.current{
            color: $utilColor;
            &:after{
                background-color: $utilColor;
            }
        }
        .listing-used-filters-head .listing-used-filters-head-button p a,
        .method-button-payment-bar button,
        .listing-tile .listing-tile-drivePowerTrains,
        .listing-tile .listing-tile-all-price,
        .section-summary__content .trim,
        .vehicle-overview__vehicle-title .vehicle-stockno,
        .vehicle-overview__vehicle-title .vehicle-vinno,
        .cash-section.tile-payment-info .payment-row,
        .listing-new-tile .listing-new-tile-drivePowerTrains,
        .listing-new-tile .listing-tile-vin,
        .listing-new-tile .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-stock,
        .listing-new-tile .listing-tile-package-description{
            color: #000000;
        }
        .listing-used-filters-head .listing-used-filters-head-button p a,
        .method-button-payment-bar button{
            font-family: $boldFontFamily;
        }
        .listing-used-payment-search .input-search__clearbtn,
        .method-button-payment-bar{
            border-color: #000000;
            color: #000000;
        }
        .listing-used-payment-search .input-search input{
            color: #000000;
            &::placeholder{
                color: #000000;
            }
        }
        /** CATALOG **/
        #catalog,
        .listing-used{
            background-color: #f2f2f2;
        }
        .vehicle-note__wrapper{
            background: #f2f2f2;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected,
        .vehicle-tile-rebate-single .is-price{
            color: $utilColor;
        }
        .section-showroom-header__progressBar-stage.selected .progressBar-stage__active-effect{
            background-color: $utilColor !important;
            fill: $utilColor !important;
        }
        .section-checkout-header__progressBar-stage{
            border-radius: 0px;
            &.selected{
                background-color: $utilColor;
                border-color: $utilColor;
                .progressBar-stage__active-effect{
                    background-color: $utilColor !important;
                    fill: $utilColor !important;
                }
            }
            &.validated{
                color: #000000;
                border-color: #000000;
                .picto-showroom:first-child{
                    color: $utilColor;
                }
            }
        }
        .vehicle-rebate .vehicle-rebate-single icon.is-price{
            color: $utilColor;
        }
        .style-color__button.selected{
            border-color: $utilColor;
        }
        .selection-year-bloc{
            border-radius: 0px;
            &:hover{
                border-color: #000000;
                .selection-year-bloc__selectButton{
                    background-color: #000000;
                }
            }
        }
        .selection-year-bloc__paymentMethod--values .cashdown-highlight,
        .selection-year-bloc__paymentMethod--values .terms-highlight,
        .selection-year-bloc__disclaimer span:first-child,
        .car-equipments_summary .features-bloc,
        .style-color__label,
        .car-equipments_summary .specs-bloc,
        .car-equipments_summary .specs-bloc--content span,
        .vehicle-tile-rebate-single,
        .vehicle-item .vehicle-description .vehicle-name,
        .vehicle-item .vehicle-description .vehicle-name .vehicle-body,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price,
        .vehicle-item .vehicle-description .vehicle-cost,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price,
        .vehicle-item .vehicle-payment .vehicle-payment-title,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount .vehicle-payment-amount-note,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .taxes-detail,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cost-credit,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate .vehicle-payment-rate-note{
            color: #000000;
        }
        .vehicle-tile-rebate-single .icon-electric-vehicle.sr-sucessColor{
            color: $utilColor;
        }
        .section-showroom-header__progressBar-stage.validated{
            color: $utilColor;
            .icon-showroom{
                color: $utilColor;
            }
        }
        .style-trim__lists__item{
            border-radius: 0px;
            border-color: #000000;
            box-shadow: none;
            &.selected.has-price-background{
                background-color: $utilColor;
                border-color: $utilColor;
            }
            .style-trim-btn{
                color: #000000;
            }
            &.selected{
                .style-trim-btn{
                    color: #ffffff;
                }
            }
        }
        .bloc-options__single{
            border-radius: 0px;
            &.selected{
                background-color: #000000;
            }
            &--footer{
                .option-choose-cta{
                    border-radius: 0px;
                    box-shadow: none;
                    background-color: #000000;
                    border-color: #000000;
                    color: #FFFFFF;
                    icon,.is-util{
                        color: #FFFFFF;
                    }
                }
                &.selected .option-choose-cta{
                    background-color: #FFFFFF;
                    border-color: #FFFFFF;
                    color: #000000;
                    icon{
                        color: #000000;
                    }
                }
            }
            &--body{
                color: #000000;
                .option-list .option{
                    color: #000000;
                }
            }
        }
        .vehicle-item .vehicle-info,
        .vehicle-item .vehicle-description,
        .vehicle-item .vehicle-payment,
        .section-summary__content .car-equipments_summary>*,
        .vehicle-overview__summary,
        .TradeInTile--block, .TradeInTile--content-estimate, .TradeInTile--content-official{
            border-radius: 0px;
        }
        /** VDP **/
        .TradeInTile--content-estimate-text{
            .sr-text.is-small{
                color: $utilColor !important;
            }
        }
        .vehicleBanner-personsViewed{
            background: rgba(0,0,0,0.8);
            border-radius: 0px;
            .amount.is-price{
                color: $utilColor;
            }
        }
        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: rgba(0,0,0,0.8);
            border-radius: 0px;
        }
        .tile-payment-info .payment-rebates .payment-rebate{
            color: #000000;
            .rebate-title icon{
                color: $utilColor;
            }
        }
        .showroom-vdp-used-features-vehicle-info,
        .showroom-vdp-used-features-vehicle-info-title,
        .showroom-vdp-used-features-vehicle-info-value,
        .showroom-vdp-used-features-vehicle-info-fuel-info,
        .showroom-vdp-used-features-vehicle-info-fuel-name,
        .financing-section.tile-payment-info .tile-payment-rate,
        .financing-section.tile-payment-info .tile-frequency,
        .vehicle-overview__vehicle-title .trim,
        .financing-section.tile-payment-info .tile-payment-detail.small,
        .financing-section.tile-payment-info .tile-payment-detail,
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .VehicleDisclaimersContent p,
        .showroom-vdp-used-options .showroom-vdp-used-options-list .showroom-vdp-used-options-item
        {
            color: #000000;
        }
        .showroom-vdp-used-features-vehicle-info,
        .vdp-information__title{
            border-color: #000000;
        }
        .vdp-used-form{
            form .sr-title-2.is-alternate,
            form .visit-step{
                color: #000000;
            }
        }
        .showroom-vdp-used-features-vehicle-info-title{
            text-transform: unset;
        }
        /** Forms **/
        .TextArea.styled-placeholder,
        .InputText.styled-placeholder,
        .InputText .input-control,
        .InputAutoComplete .input-control,
        .styled-placeholder.dropdown .Select-control{
            border-radius: 0px;
            border-color: #000000;
            &::placeholder{
                color: #000000;
            }
        }
        .TextArea.styled-placeholder{
            box-shadow: none;
            border: 1px solid #000000;
            textarea{
                color: #000000;
                &::placeholder{
                    color: #000000;
                }
            }
        }
        .InputText .input-control,
        .InputText.styled-placeholder>.input-label,
        .Select--single>.Select-control .Select-value,
        .Select-placeholder,.styled-placeholder.dropdown.not-empty .input-label,
        .InputNumber.light-theme .InputNumber--label,
        .section-catalog-filter .catalog-filter-search .catalog-filter-input-search input{
            color: #000000;
        }
        .section-catalog-filter .catalog-filter-search .catalog-filter-input-search input::placeholder{
            color: #000000;
        }
        .styled-placeholder.third,
        .styled-placeholder.full,
        .styled-placeholder.half,
        .styled-placeholder.double-third{
            height: 50px;
        }
        input[type=radio]+label:after{
            left: 6px;
        }
        .officialTradeIn__section.carStory.is-open input[type=radio]+label:after,
        .officialTradeIn__section.carCondition.is-open input[type=radio]+label:after{
            left: 7px;
        }
        .estimate__content .rc-slider .rc-slider-handle{
            background-color: $utilColor;
        }
        .estimate-value__wrapper .rc-slider{
            background: linear-gradient(to right, #E10600 0%,$utilColor 100%);
        }
        .estimate__content .slider__content--value.high-value,
        .TaxSavingItem__body-right .sr-subtitle-2{
            color: $utilColor !important;
        }
        .estimate__content .slider__content--value.low-value{
            color: #E10600 !important;
        }
        .range-value_column.low_value{
            background-color: #E10600 !important;
        }
        .range-value_column.high_value{
            background-color: $utilColor !important;
        }
        .sr-modal__close,
        .widget-profile-popupElement form#profileForm .login-radio{
            color: #000000;
        }
        .widget-profile-popupElement form#profileForm .login-radio:before,
        .vehicle-form .trade-in-talktrack{
            border-color: #000000;
        }
        .InputNumber--minus, .InputNumber--add{
            border-radius: 0px;
        }
        .InputNumber.light-theme input,
        .InputNumber.light-theme button{
            border-color: #000000;
            color: #000000;
        }
        .Appointment__content{
            .appointment__steps--single{
                .sr-subtitle-2.is-util{
                    color: #000000;
                }
                .button-cta-wrapper--single.selected{
                    background-color: $utilColor;
                }
                .button-cta-wrapper--single:first-child,
                .button-cta-wrapper--single:last-child{
                    border-radius: 0px;
                }
                &.is-validated{
                    .title_wrapper>icon{
                        color: $utilColor;
                    }
                }
            }
            .datepicker.styled-placeholder{
                border-radius: 0px;
            }
        }
        /** Calculator **/
        .paymentCalculator__buttons #calculator-toggle-button.sr-button-1{
            background: $utilColor !important;
            border-color: $utilColor !important;
        }
        .sr-paymentCalculator-background{
            .paymentCalculator__payment{
                .is-util{
                    color: #FFFFFF;
                }
            }
            .paymentCalculator__rebates--term.is-light{
                color: #FFFFFF;
            }
        }
        .calculatorRow{
            .is-alternate,
            .sr-paymentCalculator-EmphasisColor{
                color: #000000;
            }
        }
        .calculator-tradeIn .input-format-currency input,
        .calculatorColumn__disclaimer{
            color: #000000;
        }
        .purchase-methods-cash .purchase-detail-line.price_total{
            background-color: #FFFFFF;
            border-color: #000000;
            .sr-paymentCalculator-EmphasisColor,
            .is-util{
                color: #000000;
            }
        }
        .purchase-methods-cash .purchase-detail-line .is-util,
        .InputNumber--add, .InputNumber--minus,
        .calculatorColumn .InputNumber input,
        .sr-paymentCalculator__calculatorTerm--background .price span{
            color: #000000 !important;
        }
        .purchase-methods-cash .purchase-detail-line .icon-promo2.is-util{
            color: $utilColor !important;
        }
        .calculatorColumn .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-control,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-option,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-menu-outer .Select-menu-option{
            background-color: #ffffff !important;
            color: #000000 !important;
            border: 1px solid #000000 !important;
        }
        .sr-paymentCalculator__calculatorTerm--background{
            border: 1px solid #000000;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .term{
            background-color: $utilColor !important;
        }
        .Select-menu-outer{
            background: #ffffff !important;
            color: #000000 !important;
        }
        .Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label,
        .Select.has-value.Select--single>.Select-control .Select-value .Select-value-label{
            color: #000000 !important;
        }
        .paymentCalculator__paymentsInfos .paymentCalculator__tradeIn--term.is-light,
        .paymentCalculator__paymentsInfos .paymentCalculator__payment .paymentCalculator__payment--details.is-light{
            color: #FFFFFF;
        }
        .calculator-term,
        .calculator-tradeIn .input-format-currency{
            border-radius: 0px;
        }
        .purchase-methods-cash{
            .purchase-detail-line{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .clearfix{
                &:after{
                    content: none !important;
                }
                &:before{
                    content: none !important;
                }
                span.label.rebate.sr-paymentCalculator-EmphasisColor{
                    display:flex;
                    align-items: center;
                }
            }
        }
        .purchase-methods-cash .obligationsTotal .price-title, .purchase-methods-cash .pdsf_calculator .price-title,
        .purchase-methods-tab__tabs li button,
        .purchase-methods-cash .purchase-detail-line.price_total .label{
            text-transform: unset;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__hyundai{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}
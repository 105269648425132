/* website: 3389-hyundaiileperrot
 * created at 2021-05-28 8:30 by fbbreard
 */
// Import all makes and organization styles files
@import "../templates/makes/hyundai.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr {
        &.dealer__autoforce-hyundai {
        .LoginBanner{
            padding: 0px!important;
        }
        .LoginBanner .LoginBannerWrapper {
          height: 34px;
          max-height: 34px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 12px !important;
              .label{
                font-size: 12px !important;
              }
            }
  
            #button-login.btn-login {
              margin-left: 10px;
            }
  
            #button-register.btn-register {
              margin-left: 10px;
              padding: 5px !important;
            }
          }
        }
      }
    }
  }

.widget-sr{
    @include clearfix;
    &.dealer__autoforce-hyundai{
        /** Hide non-trade360 trade in form **/
        .TradeInTile--block:not(:first-child){
            display: none;
        }
        .TransactionAction--wrapper .TransactionAction--single:nth-of-type(2) .TransactionAction--details .sr-button-outline-1 {
            display: none;
        }
        .TransactionSummary--wrapper--title:after {
            font-family: showroom!important;
            content: '\e9a5';
            transform: rotate(180deg);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            transition: all 0.3s ease-in-out;
            width: 12px;
        }
        
        .TransactionSummary--wrapper--title.is-open:after {
            content: '';
        }
    }
}